<template>
    <div>
        <b-card>
            <img class="card-img rounded-0" :src="trading.image" alt="Image">

            <div class="on-live">
                {{ formatDate(trading.day) }}
            </div>

            <div class="class-title">
                {{ trading.name }}
            </div>
            <div class="class-description mt-3 mb-3">
                {{ trading.description }}
            </div>
            <div class="mt-2">
                <div class="row">
                    <div class="col-6">
                        <b-button class="w-100" variant="secondary" @click="modalDelete = true">
                            Delete
                        </b-button>
                    </div>
                    <div class="col-6">
                        <b-button class="w-100" variant="outline-primary" @click="modalEdit = true">
                            Edit
                        </b-button>
                    </div>
                    <div class="col-12">
                        <b-button class="w-100 mt-3" @click="changeAvailable" variant="primary">
                            Start Class
                        </b-button>
                    </div>
                </div>
            </div>
        </b-card>
        <b-modal size="lg" title="Update trading Live" centered hide-footer v-model="modalEdit">
            <Form :trading="trading" @close="modalEdit = false" />
        </b-modal>
        <b-modal centered hide-footer title="Delete membership" v-model="modalDelete">
            <h4>
                Sure you want delete?
            </h4>
            <div class="text-right">
                <b-button variant="secondary" class="mr-3" @click="modalDelete = false">
                    Cancel
                </b-button>
                <ButtonAction :loading="loading" loadingTx="Deleting membership" title="Delete Membership" @click="confirmeDelete(trading.id)"/>
            </div>
        </b-modal>
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import Form from './Form.vue'
export default {
    props: ['trading'],
    components: {
        Form
    },
    data () {
        return {
            modalEdit: false,
            modalDelete: false
        }
    },
    methods: {
        ...mapActions('tradingLive', ['destroyTrading', 'togleStatus']),
        formatDate (date) {
            let today = moment().format('Y-MM-DD')
            if(today == moment(date).format('Y-MM-DD')) {
                return 'On Live'
            } else {
                return moment(date).format('Y-MM-DD')
            }
        },
        confirmeDelete (id) {
            this.loading = true
            this.destroyTrading(id).then(() => {
                this.loading = false
            })
        },
        changeAvailable () {
            this.togleStatus(this.trading.id).then(() => {
                this.$router.push({ name: 'Meet-Live', params: { id: this.trading.id, name: this.deleteSpaces(this.trading.name) } })
            })
        },
        deleteSpaces (name) {
            return name.replace(/ /g, '-');
        }
    }
}
</script>
<style scoped>
    .class-title{
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
    }
    .class-description{
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
    }
    .on-live{
        background-color: rgba(255, 89, 90, 1);
        position: absolute;
        top: 30px;
        right: 30px;
        padding: 5px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }
</style>