<template>
    <div>
        <div class="text-right mb-3">
            <b-button variant="primary" size="lg" @click="modalCreate = true">
                Create Trading Live
            </b-button>
        </div>
        <div v-if="loading == false">
            <div class="row" v-if="tradings.length > 0">
                <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3" v-for="trading in tradings" :key="trading.id">
                    <CardLive :trading="trading" />
                </div>
            </div>
            <Empty v-else/>
        </div>
        <Loading v-else/>
        <b-modal size="lg" centered hide-footer title="Crear Trading Live" v-model="modalCreate">
            <Form @close="modalCreate = false" />
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Form from './Form.vue'
import CardLive from './CardLive.vue'
export default {
    components: {
        CardLive,
        Form
    },
    data () {
        return {
            loading: false,
            modalCreate: false
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('tradingLive', ['getTradings']),
        getData () {
            this.loading = true
            this.getTradings().then(() => {
                this.loading = false
            })
        }
    },
    computed: {
        ...mapState('tradingLive', ['tradings'])
    }
}
</script>