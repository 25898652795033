<template>
    <div>
        <div class="row">
            <div class="col-12 col-sm-8">
                <span>Name</span>
                <b-form-input v-model="form.name"/>
            </div>
            <div class="col-12 col-sm-4">
                <span>Day</span>
                <b-form-input type="date" v-model="form.day"/>
            </div>
            <!-- <div class="col-12 col-sm-3">
                <span>Time</span>
                <b-form-input type="time" v-model="form.price"/>
            </div> -->
            <div class="col-12 pt-4">
                <span>Description</span>
                <b-form-input v-model="form.description"/>
            </div>
            <div class="col-12 pt-4">
                <span>Upload image</span>
                <input
                type="file"
                class="form-control rounded-xs"
                ref="image"
                placeholder="Your Password"
                @change="changeFiles"
                />
            </div>
            <div class="text-right mt-3">
                <b-button variant="secondary" class="mr-3">
                    Cancel
                </b-button>
                <ButtonAction :loading="loading" loadingTx="Uploading trading live" title="Create trading live" @click="onSubmit" v-if="!trading"/>
                <ButtonAction :loading="loading" loadingTx="Uploading trading live" title="Update trading live" @click="onUpdate" v-else/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: ['trading'],
    data () {
        return {
            form: {
                name: null,
                day: null,
                description: null,
                image: 'asdf'
            },
            loading: false
        }
    },
    created () {
        if(this.trading) {
            this.form = this.trading
        }
    },
    methods: {
        ...mapActions('tradingLive', ['storeTrading', 'updateTrading']),
        changeFiles(){
            this.form.image = this.$refs.image.files[0]
        },
        onSubmit () {
            this.loading = true
            const formData = new FormData()
                formData.append('name', this.form.name)
                formData.append('day', this.form.day)
                formData.append('description', this.form.description)
                formData.append('file', this.form.image)
            
            this.storeTrading(formData).then(() => {
                this.loading = false
                openNotification()
                this.$emit('close')
            })
        },
        onUpdate () {
            this.loading = true
            const formData = new FormData()
                formData.append('id', this.form.id)
                formData.append('name', this.form.name)
                formData.append('day', this.form.day)
                formData.append('description', this.form.description)
                formData.append('image', this.form.image)
            
            this.updateTrading({data: formData, id: this.form.id}).then(() => {
                this.loading = false
                openNotification()
                this.$emit('close')
            })
        }
    }
}
</script>